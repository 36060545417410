@import "/src/app/styles/for-components";

.card{
    position: relative;
    width: 100%;
    height: fit-content;
    margin: auto;
    margin-top: 2rem;
    border-radius: var(--borderMedium);
    box-shadow: var(--shadow);
    min-width: 300px;
    @include break-up(desktop){
        margin: 2rem auto;
    }
    .card-chips{
        position: absolute;
        top: 1rem;
        left: 1rem;
        display: flex;
        gap: 0.5rem;
        z-index: 1;
        .card-chip{
            padding: 0.5rem;
            border-radius: var(--borderSmall);
            &.novelty{
                background-color: var(--blue) ;
                color: var(--white);
            }
            &.promoted{
                background-color: var(--yellow) ;
                color: var(--black) ;
            }
            &.isSold{
                background-color: var(--red);
                color: var(--white) ;
            }
        }
    }
    & .card-discount{
        position: absolute;
        top: 1rem;
        left: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        border-radius: var(--borderSmall);
        background-color: var(--green);
        color: var(--white);
        z-index: 1;
        cursor: pointer;
        & svg{
            animation: Bounce 2s infinite ease-in-out;
        }
        & div{
            position: absolute;
            display: flex;
            width: max-content;
            left: 110%;
            padding: 0.5rem;
            background: var(--green);
            border-radius: var(--borderSmall);
            opacity:0;
            visibility: hidden;
        }
        &:hover div{
            opacity:1;
            visibility: visible;
        }
    }
    & .card-carousel{
        width: 100%;
        height:14em;
        background-color: var(--light-grey);
        border-top-right-radius: var(--borderMedium);
        border-top-left-radius: var(--borderMedium);
        overflow: hidden;
        &>.emptyImg{
            position: relative;
            width: 100%;
            height: 100%;
            background-color: var(--dark-grey);
            display:flex;
            flex-flow: column wrap;
            justify-content:center;
            align-items:center;
            gap: 1rem;
            color: var(--white);
            text-align: center;
            & svg{
                height: 30%;
            }
        }
        & .splide__arrow--prev{
            left: 0.5em;
        }
        & .splide__arrow--next{
            right: 0.5em;
        }
    }
    & .card-info{
        padding: 1rem;
        display: flex;
        flex-flow: column nowrap;
        gap: 0;
    }
    & .card-room{
        font-size: 0.75rem;
    }
    & .card-title{
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        height: 4rem;
        margin-top: 0.5rem;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: baseline;
            -ms-flex-align: baseline;
                align-items: baseline;
    }
}

.react-loading-skeleton.skeleton-card-title{
    height:1rem ;
    margin-bottom: 0.7rem;
}

.card-icone{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    &.pet{
        margin: 0 2px 0 5px;
    }
    &.smoke{
        margin-left: 3px;
    }
    &.tv{
        margin-left: 2px;
    }
}

.card-equipment{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    row-gap: 1rem;
    margin-bottom: 1rem;
    gap: 1rem;
    min-height: 30px;
    &.sale svg:not(.charge){
        margin-right: 0.3rem;
    }
    &.rent{
        justify-content: flex-start;
        gap: 0.8rem;
    }
}

.card-price{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    row-gap: 1rem;
    min-height: 43px;
    & div{
        display: flex;
        flex-direction: column;
        & .period-span{
            font-style: italic;
            font-size: 14px;
            color: var(--blue);
            font-weight: 300;
        }
    }
    & span{
        min-width: 10rem;
    }
}

/*CARD POST*/

.cardPost{
    display: block;
    width: 100%;
    max-width: 20rem;
    min-width: 10rem;
    height: auto;
    margin: auto;
    margin: 1rem auto;
    padding: 1rem;
    border-radius: 20px;
    box-shadow: var(--shadow);
    overflow: hidden;
    & .cardPost-image{
        width: 100%;
        height: 12em;
        border-radius: 15px;
      background: #ebebeb;
      overflow: hidden;
      & > img { width: 100%; }
    }
    & .cardPost-activity {
        display: flex;
        justify-content: center;
        width: 80%;
        padding: 1rem 0;
        margin: auto;
        border-bottom: 1px solid #B5B5B5;
        color: var(--red);
        &>.cardPost-Date {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            font-size: 0.66rem;
        }
    }
    &.skeleton .cardPost-activity{
        display: block;
    }
    & .cardPost-title {
        width: 95%;
        margin: auto;
        padding: 1rem 0;
        text-align: center;
    }
}


/*CARD IMAGE CAROUSEL*/
.splide__arrow.cardImage-arrow{
    background-color: var(--white);
    opacity:1;
    height: 1.7rem;
    width: 1.7rem;
    & svg{
        width: 0.8rem
    }
}


@keyframes Bounce {
    0%{
        transform: scale(1.2);
    }
    50%{
        transform: scale(1.4);
    }
    100%{
        transform: scale(1.2);
    }
}
